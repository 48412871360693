<template>
  <v-dialog v-model="display" max-width="750px">
    <v-card class="pb-3">
      <v-card-title class="primary pt-2 pb-3">

      </v-card-title>

      <v-card-text class="pa-0">
        <v-layout row wrap>
          <v-flex xs12 class="px-5 pt-3">
            <p
              :class="{'font-45': $vuetify.breakpoint.smAndUp, 'font-35 text-xs-left': $vuetify.breakpoint.xsOnly}"
              class="graphik-medium normalcase primary--text mb-0"
            >{{ $ml.get('quoter_chubb_offline_sorry_inconveniences') }}</p>
            <p
              :class="{'font-20': $vuetify.breakpoint.smAndUp, 'font-17 text-xs-left': $vuetify.breakpoint.xsOnly}"
              class="graphik-light normalcase dark-grey--text"
            >{{ $ml.get('quoter_chubb_offline_schedule') }}</p>
          </v-flex>

          <v-flex xs12>
            <v-img
              max-width="250px"
              :class="{'ma-auto': $vuetify.breakpoint.xsOnly}"
              position="center left"
              :src="images.chubb_logo"
              :lazy-src="images.chubb_logo"
            />
          </v-flex>

          <v-flex xs12 :text-xs-right="$vuetify.breakpoint.smAndUp" :text-xs-center="$vuetify.breakpoint.xsOnly" class="px-5 pb-3">
            <v-btn 
              color="primary"
              depressed dark 
              class="normalcase graphik-bold-italic ma-0 border-radius-5"
              @click="display = false"
            ><v-icon dark small class="mr-2">arrow_back</v-icon>{{ $ml.get('general_back') }}</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'QuoterDialogChubbOffline',
  props: {
    show: Boolean
  },
  computed: {
    display: {
      get () {
        return this.show
      },
      set () {
        this.$emit('close')
      }
    },
  },
  data () {
    return {
      images: {
        chubb_logo: require('@/assets/img/companies/chubb-full.png')
      }
    }
  }
}
</script>

<style scoped>
</style>
